
<template>
  <div>
    <v-card-text>
      <v-card  outlined class="white pa-2">
        <v-alert type="info" text
          >Esta sección busca darle soporte al usuario relacionado a los
          módulos, términos y campos que contiene el portal, así como los
          procedimientos a llevar a cabo para realizar movimientos dentro del
          mismo.</v-alert
        >
        <div style="background: white">
          <iframe
            id="frameID"
            ref="myframe"
            style="border: none"
            width="100%"
            :src="iframe.src"
            :height="window.height"
          ></iframe>
        </div>
      </v-card>
    </v-card-text>
  </div>
</template>

<script>
import { global } from "@/api/global";

export default {
  data: () => {
    return {
      preview: false,
      window: {
        width: 0,
        height: 0,
      },
      iframe: {
        src: global.baseWebUrl + "/DocumentosCompartidos/ayuda.htm",
        style: null,
        wrapperStyle: null,
        // height: 1000
      },
      breadcrumb: [
        {
          text: "Ayuda",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight - 210;
    },
    // getIFElement() {
    //   let iframe = document.getElementById("frameID");
    //   process.nextTick(this.setHeight(iframe));
    // },
    // setHeight(iframe) {
    //   // iframe.contentWindow.document.getElementsByTagName('HTML').style.overflow = 'hidden'
    //   iframe.contentWindow.document.getElementsByTagName(
    //     "HTML"
    //   )[0].style.overflow = "hidden";
    //   // let htmlArray = [...bodies]

    //   //
    //   let bodies = iframe.contentWindow.document.getElementsByTagName("BODY");
    //   // let bodiesArray = [...bodies]
    //   let h = bodies[0].offsetHeight;
    //   this.iframe.height = h;
    // }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    // this.$store.dispatch("setBreadcrumbs", this.breadcrumb);
    this.iframe.style = {
      position: "absolute",
    };
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>